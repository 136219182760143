<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"
			:is_item="true"

			@change="getData"
			@click="getData"
			@toItem="onItem"
		>
			<select
				slot="add"
				v-model="search.pg_code"
				class="pa-5-10 mr-10"

				@change="getSearch(1)"
			>
				<option value="">PG사</option>
				<template
					v-for="(code, index) in codes.P004.items"
				>
					<option
						:key="code.total_code + '_' + index"
						:value="code.code_value"
					>{{ code.code_name }}</option>
				</template>
			</select>
		</Search>

		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<div class="full-width">
				<table class="table table-even">
					<colgroup>
						<col width="60px" />
						<col width="120px" />
						<col width="180px" />
						<col width="120px" />
						<col width="120px" />

						<col width="120px" />
						<col width="120px" />
						<col width="120px" />
						<col width="150px" />
						<col width="120px" />

						<col width="120px" />
						<col width="150px" />
						<col width="auto" />

					</colgroup>
					<thead>
					<tr>
						<th>
							<button
								@click="selectAll"
							>
								<v-icon v-if="is_all" class="color-primary">mdi-checkbox-marked</v-icon>
								<v-icon v-else >mdi-checkbox-blank-outline</v-icon>
							</button>
						</th>
						<th>PG사</th>
						<th>PG명</th>
						<th>결제 금액</th>
						<th>승인 코드</th>
						<th>승인 번호</th>
						<th>카드사</th>
						<th>구매자</th>
						<th>결제일시</th>
						<th>등록일</th>
						<th>재전송</th>
						<th>재전송일</th>
						<th>상세정보</th>
					</tr>
					</thead>
					<tbody
						v-if="items.length > 0"
					>
					<tr
						v-for="item in list_noti"
						:key="item.uid"
					>
						<td>
							<button
								@click="$set(item, 'is_select', !item.is_select)"

								:disabled="!item.is_approval"
							>
								<v-icon v-if="item.is_select" class="color-primary">mdi-checkbox-marked</v-icon>
								<v-icon v-else >mdi-checkbox-blank-outline</v-icon>
							</button>
						</td>
						<td>{{ item.pg_code }}</td>
						<td>{{ item.pg_name }}</td>
						<td>{{ Number(item.totAmt) | makeComma }}</td>
						<td>{{ item.tranStatus }}</td>
						<td style="word-break: break-word">{{ item.appNo }}</td>
						<td>{{ item.issNm }}</td>
						<td>{{ item.d_name }}</td>
						<td>{{ item.tranDt }}</td>
						<td>{{ item.wDate }}</td>
						<td>{{ item.is_noti }}</td>
						<td>{{ item.nDate }}</td>
						<td>
							<button
								class="bg-identify pa-5-10"
								@click="toDetail(item)"
							>상세정보</button>
						</td>
					</tr>
					</tbody>
				</table>

				<div
					class="mt-10 text-right"
				>
					<button
						class="btn-inline btn-primary size-px-12 pa-10-20"
						:disabled="is_disabled"
						@click="onIsNoti"
					>선택 노티 전송</button>
				</div>

				<Empty
					v-if="!items.length"
				></Empty>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
		</div>

		<Popup_confirm
			v-if="is_on_noti"
			@cancel="offIsNoti"
			@click="postNoti"
		>
			<template
				v-slot:title
			>결제 노티 재전송</template>
			<template
				v-slot:main-txt
			>선택한 결제 노티를 재전송하시겠습니까?</template>
		</Popup_confirm>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "@/view/Layout/Empty";
import Popup_confirm from "../Layout/PopupConfirm";

export default {
	name: 'OrderReturn'
	, props: ['user', 'codes']
	, components: {Popup_confirm, Search, Pagination, Empty}
	, data: function(){
		return {
			program: {
				name: '노티 내역'
				, top: true
				, title: true
				, bottom: false
			}
			, search: {
				pg_code: ''
				, pg_type: ''
				, pg_status: ''
				, pg_able: ''
				, page: 1
				, list_cnt: 10
				, search_type: 'keyword'
				, account_type: ''
				, agency_upper: ''
				, sDate: ''
				, eDate: ''
			}
			,search_option:{

				is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '검색어', column: 'keyword'}
				]
				,select: [

				]
				, sDate: true
				, eDate: true
			}
			, items: []
			, items_upper: []
			, is_modal_item: false
			, item_delete: { }
			, item_info: { }
			, is_all: false
			, is_on_noti: false
		}
	}
	, computed: {
		pg_list: function(){
			let t = []
			this.codes['P004'].items.filter((item) => {
				console.log(item)
			})
			return t
		}
		, is_disabled: function(){
			let t = true
			this.items.filter( (item) => {
				if(item.is_select){
					t = false
				}
			})
			return t
		}
		, noti_list: function(){
			let t = []
			this.items.filter( (item) =>{
				if(item.is_select){
					t.push(item.uid)
				}
			})
			return t
		}
		, list_noti: function(){
			return this.items.filter( (item) => {
				if(Number(item.totAmt) > 0 && item.issNm){
					item.is_approval = true
				}

				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getOrderReturn'
					,data: this.search
				})
				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.search.page = page
			this.getData()
		}
		, onItem: function(){
			this.item_info = { }
			this.is_modal_item = true
		}
		, onItemDelete: function(item){
			if(confirm("해당 PG사 정보를 삭제하시겠습니까?")){
				this.postDelete(item)
			}
		}

		, postUpdate: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postPgItemUpdate'
					,data: {
						uid: item.uid
						, pg_status: item.pg_status
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
				this.is_confirm2 = false
			}
		}
		, postDelete: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postDeletePgItem'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.is_modal_item = true
			this.item_info = item
		}
		, selectAll: function(){
			this.is_all = !this.is_all
			this.items.filter( (item) => {
				if(item.is_approval){
					this.$set(item, 'is_select', this.is_all)
				}
			})
		}
		, onIsNoti: function(){
			this.is_on_noti = true
		}
		, offIsNoti: function(){
			this.is_on_noti = false
		}
		, postNoti: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postNoti'
					,data: {
						uids: this.noti_list
					}
				})
				if(result.success){
					this.offIsNoti()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	, watch: {

	}
}
</script>